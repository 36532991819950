

import Footer from "./components/Footer"
import Nav from "./components/Nav"
import VotingCard from "./components/VotingCard"


const ExploreVote = ()=>{


    return(
        <>
          <header>
      <Nav/>
    </header>

    <VotingCard/>
    <Footer/>
        </>
    )
}

export default ExploreVote;