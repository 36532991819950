

const Footer = ()=>{

    return(
        <>
        <div className="w-full mt-10 pt-10 px-5 pb-3 bg-[#c7e8f5] ">
            <img src="https://i.ibb.co/7G6NNJR/logo.png" alt="seaevolution" className="w-[200px] h-[50px]" />
            <span className="mt-3 text-black font-black">&copy; 2023 all rights reserved Sea Evolution</span>
        </div>
        
        
        </>
    )
}



export default Footer ;